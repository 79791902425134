export default {
    // "opening_hours": "Öffnungszeiten",
    // "closed": 'Geschlossen',
    // "day_of_week": {
    //     'mo': 'Montag',
    //     'di': 'Dinstag',
    //     'mi': 'Mittwoch',
    //     'do': 'Donerstag',
    //     'fr': 'Freitag',
    //     'sa': 'Samstag',
    //     'so': 'Sonntag',
    // }
}
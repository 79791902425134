<template>
  <div class="mx-wrapper mx-auth">
    <mx-preloader :loader-status="preloader"/>
    <div class="mx-container">
      <div class="mx-auth-logo"></div>
      <router-view/>
      <div class="mx-auth-footer">
        {{ options.version }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      preloader: true
    }
  },
  mounted() {
    this.preloader = false;
  },
}
</script>

export default {
  "first_name": "Vorname",
  "last_name": "Nachname",
  "password": "Passwort",
  "email": "Email",
  "add_user": "Neu Benutzer",
  "identification": "Personalnummer",
  "status": {
    "active": "Aktiv",
    "not_active": "Inaktiv",
  },
  "employment": {
    "intern": "Intern",
    "extern": "Extern",
    "client": "Kunde",
  },
  "gender": {
    "title": "Geschlecht",
    "female": "Weiblich",
    "male": "Männlich",
    "divers": "Divers",
    "indefinite": "Unbestimmt"
  },
  "cover": {
    "success": "Ok",
    "abort": "Abrechnen",
    "select_avatar": "Avatar auswählen",
    "other_avatar": "Andere avatar",
  },
  "alert": {
    "user_data_changed": "Sie haben gerade Benutzerdaten geändert. Bitte speichern oder brechen Sie Ihre Aktionen."
  }
}

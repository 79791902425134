import { http, httpFile } from "../http_service";


/** FAQ **/
export function faqs(company_id) {
    return http().get(`/faq?company_id=${company_id}`)
}


export function faqEdit(data, id) {
    return http().put(`/faq/${id}`, data)
}

export function faqCreate(data) {
    return http().post(`/faq`, data)
}

export function faqDelete(id) {
    return http().delete(`/faq/${id}`)
}

export function faqRemove(id) {
    return http().delete(`/faq/remove/${id}`)
}


/** Question **/
export function questionCreate(data) {
    return http().post('question', data)
}

export function questionUpdate(data, id) {
    return http().put(`question/${id}`, data)
}

export function questionDelete(id) {
    return http().delete(`question/${id}`)
}
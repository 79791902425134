/* Middleware */
import auth from '@/middleware/auth'

export default [
    {
        path: '/cabinet/company/:company_id/cad/create',
        name: 'cad.create',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/cad/CadCreateView'),
    },
    {
        path: '/cabinet/company/:company_id/cad/edit',
        name: 'cad.edit',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/cad/CadEditView'),
    },
];
<template>
  <transition name="loader-fade">
    <div :style="`z-index: ${zIndex} ;`" v-if="loaderStatus" class="mx-loader" :class="{'mx-loader__load' : loaderStatus}">
      <img v-if="logo" src="/assets/images/preloader.png" alt="preloader">
      <img v-if="spiner" style="max-width: 38px; margin-left: 44px;" src="/assets/images/preloader-2021.gif" alt="preloader">
    </div>
  </transition>
</template>

<script>
  export default {
    name: "Preloader",
    props: {
      loaderStatus: {
        type: Boolean,
        default: true,
        required: true,
      },
      zIndex: {
        type: Number,
        default: 10000
      },
      logo: {
        type: Boolean,
        default: true
      },
      spiner: {
        type: Boolean,
        default: true
      }
    },
  }
</script>
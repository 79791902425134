import user from './user/_lang_user'
import auth from './_lang_auth'
import error from './_lang_errors'
import date from './_lang_date'
import company from './_lang_company'
import faq from './_lang_faq'
import document from './_lang_document'
import middleware from './_lang_middelware'

export default {
    "not_found": "Nicht gefunden",
    "user": user,
    "auth": auth,
    "error": error,
    "date": date,
    "company": company,
    "faq": faq,
    "middleware": middleware,
    "document": document,
    "copyright": '© peepz GmbH {year}, All Rights Reserved',
    "filter": {
        "button": "Aussortieren"
    },
    "search": {
        "title": "Suche nach",
        "button": "Suchen"
    },
    "language": {
        "de": {
            "de": "Deutsch",
            "en": "English",
            "ru": "Russisch"
        },
        "en": {
            "en": "English",
            "de": "Germany",
            "ru": "Russia"
        },
        "ru": {
            "ru": "Русский",
            "de": "Немецкий",
            "en": "Английский"
        }
    },
    "countries":{
        "germany": "Deutschland",
    },
    "setting": {
        "settings": {
            "title": "Einstellungen",
            "tab_title": "Einstellungen",
            "changed": "Einstellungen wurde geändert",
            "user": {
                "identification": "Personalnummer",
                "email": "Email"
            },
            "form": {
                "first_name": 'Vorname',
                "last_name": 'Nachname',
                "language": 'Sprache',
            },
            "errors": {
                "first_name": 'Vorname kann nicht leer sein',
                "last_name": 'Nachname kann nicht leer sein',
            }
        },
        "password": {
            "title" : "Passwort ändern",
            "tab_title" : "Passwort ändern",
            "form": {
                "label": 'Passwort',
                "label_confirmation": "Passwort Bestätigung",
                "password_do_not_match": "Passwörter stimmen nicht überein!",
                "password_changed": "Das Passwort wurde geändert"
            },
        }
    }
}

/* Middleware */
import auth from '@/middleware/auth'

export default [
    {
        path: '/cabinet/company/:company_id/faq',
        name: 'faq.show',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/faq/FaqShowView'),
    },
    {
        path: '/cabinet/company/:company_id/faq/edit/:faq_id',
        name: 'faq.edit',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/faq/FaqEditView'),
    },
    {
        path: '/cabinet/company/:company_id/faq/:faq_id/question/create',
        name: 'question.create',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/faq/question/QuestionCreateView'),
    },
    {
        path: '/cabinet/company/:company_id/faq/:faq_id/question/csv/import',
        name: 'question.csv.import',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/faq/question/QuestionCSVImportView'),
    },
    {
        path: '/cabinet/company/:company_id/faq/:faq_id/question/:question_id/update',
        name: 'question.update',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/faq/question/QuestionEditView'),
    },

];
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'


Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  Vue.config.productionTip = true
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

// Preloader
import Preloader from "@/components/Preloader";
Vue.component('mx-preloader', Preloader)

// Options
import options from "./options";
Vue.prototype.options = options;

/* Axios */
require('./store/subscriber');
axios.defaults.withCredentials = false;
axios.defaults.baseURL = options.default_url + '/api/';

// VueI18n
import { i18n } from './plugins/i18n'

// VueInputMask
const VueInputMask = require('vue-inputmask').default
Vue.use(VueInputMask)

// Bootstrap-Vue
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import "phosphor-vue";

// SCSS
import './assets/css/sass/themes/default.light.scss'

store.dispatch('attempt', sessionStorage.getItem('token')).then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})



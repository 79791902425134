<template>
  <div class="mx-wrapper mx-empty">
    <mx-preloader :loader-status="preloader"/>
<!--    <router-link :to="{name: 'login'}">Login</router-link>-->
<!--    <button type="button" class="btn btn-peepz-info" @click="logout">{{ $t('auth.logout') }}</button>-->
    <div class="mx-container">
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      preloader: true
    }
  },
  mounted() {
    this.preloader = false;
  },
  methods: {
    logout() {
      this.$store.dispatch('signOut')
    }
  }
}
</script>

/* Middleware */
import auth from '@/middleware/auth'

export default [
    {
        path: '/cabinet/documents',
        name: 'documents',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        redirect: "/cabinet/documents/all",
        component: () => import('@/views/cabinet/document/DocumentView'),
        children: [
            {
                path: 'all',
                name: 'document.all',
                meta: {
                    layout: 'main',
                    middleware: [auth]
                },
                component: () => import('@/views/cabinet/document/DocumentShowView'),
            },
            {
                path: 'create',
                name: 'document.create',
                meta: {
                    layout: 'main',
                    middleware: [auth]
                },
                component: () => import('@/views/cabinet/document/DocumentCreateView'),
            },
            {
                path: ':id/edit',
                name: 'document.edit',
                meta: {
                    layout: 'main',
                    middleware: [auth]
                },
                component: () => import('@/views/cabinet/document/DocumentEditView'),
            },
        ]
    },

];

import {http, httpFile, httpDocument, httpDocumentFile} from "../http_service";

/** Categories **/
export function documentCategories() {
    return http().get(`/documents/categories`);
}


/** Documents **/
export function allDocuments() {
    return http().get(`/documents`);
}

export function documentEdit(id) {
    return http().get(`/documents/${id}/edit`);
}

export function documentCreate(data) {
    return httpDocumentFile().post(`/documents`, data);
}

export function documentUpdate(data) {
    return http().put(`/documents/${data.id}`, data);
}

export function documentDelete(id) {
    return http().delete(`/documents/${id}`);
}
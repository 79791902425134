export default {
    "title": "Dokumente",
    'new_document': "Neue Dokument",
    "btn": {
        'new_document': "Neue Dokument",
        'abort': "Abrechnen"
    },
    "table": {
       "head": {
           "id": "ID",
           "name": "Name",
           "category": "Kategorie",
           "for": "Für",
           "type": "Typ",
           "created_at": "Erstellt",
           "updated_at": "Aktualisiert",
           "search": "Suchen...",
       },
        "not_found": ""
    },
    "prompt": {
        "delete": {
            "title": "Dokument löschen?",
            "subtitle": ""
        },
    },
}

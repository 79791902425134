import * as Service from '@/services/auth/auth_service'


export default {
    namespace: true,
    state: {
        token: null,
        permissions: null,
        roles: null
    },
    getters: {
        authenticated(state) {
            return state.token
        },
        permissions(state) {
            return state.permissions
        },
        roles(state) {
            return state.roles
        }
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_PERMISSIONS(state, data) {
            state.permissions = data
        },
        SET_ROLES(state, data) {
            state.roles = data
        },
        SET_LANGUAGE(state, language) {
            state.language = language
        },
    },
    actions: {

        /* Login */
        async signIn({dispatch}, credentials) {
            let response = await Service.login(credentials)
            await dispatch('attempt', response.data.meta.token)
        },

        /* Logout */
        async signOut({commit, dispatch}) {
            await Service.logout()
            await dispatch('reset')
            location.replace('/')
        },

        /* Attempt */
        async attempt({commit, state, dispatch}, token) {
            if (token) {commit('SET_TOKEN', token)}
            if (!state.token) {return;}

            try {
                await Service.me().then(response => {
                    // dispatch('companies')
                    dispatch('setup', response)
                })
            } catch (error) {
                commit('SET_TOKEN', null);
                commit('SET_USER', null);
            }
        },

        /* Setup */
        async setup({commit}, response) {
            commit('SET_USER', response.data)
            commit('SET_LANGUAGE', response.data.language)

            await sessionStorage.setItem('username', response.data.first_name + ' ' + response.data.last_name);
            await sessionStorage.setItem('email', response.data.email);
        },

        /* Reset */
        async reset({commit}) {
            commit('SET_TOKEN', null)
        }

    }
}
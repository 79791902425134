import * as Service from '@/services/faq/faq_service'
import router from "@/router"

export default {
    namespace: true,
    state: {
        faqs: [],
    },
    getters: {
        faqs(state) {
            return state.faqs
        },
        questions(state) {
            return state.faqs.questions
        }
    },
    mutations: {
        SET_FAQS(state, faqs) {
            state.faqs = faqs
        },
        SET_FAQ(state, faq) {
            let result = state.faqs.find(c => c.id === faq.id)
            if(undefined !== result) {
                Object.assign(result, faq)
            } else {
                state.faqs.push(faq)
            }
        },
        DELETE_FAQ(state, id) {
            state.faqs = state.faqs.filter(faq => faq.id !== id)
        },
        SET_QUESTION(state, data) {
            console.log(data);
        },
        SET_QUESTION_UPDATE(state, data) {
            console.log(data);
        },
        SET_QUESTION_DELETE(state, data) {
            console.log(data);
        }
    },
    actions: {
        /* Faqs */
        async faqs({commit, state}, company_id) {
            // if(state.faqs.length > 0) {
            //     return
            // }

            await Service.faqs(company_id).then(response => {
                commit('SET_FAQ', response.data)
            }).catch(error => {
                switch (error.response.status) {
                    case 404:
                        router.replace({
                            name: 'company.all'
                        })
                        break;
                }
            })
        },

        /* Faq Edit */
        async faqEdit({commit}, data) {
            await Service.faqEdit(data, data.id).then(response => {
                commit('SET_FAQ', response.data.data)
            }).catch(error => {
                switch (error.response.status) {
                    case 404:
                        router.replace({
                            name: 'company.all'
                        })
                        break;
                }
            })
        },

        /* Question create */
        async questionCreate({commit}, data) {
            await Service.questionCreate(data).then(response => {
                commit('SET_QUESTION', response.data)
            })
        },

        /* Question update */
        async questionUpdate({commit}, data) {
            console.log(data);
            await Service.questionUpdate(data, data.id).then(response => {
                commit('SET_QUESTION_UPDATE', response.data)
            })
        },

        /* Question delete */
        async questionDelete({commit}, id) {
            await Service.questionDelete(id).then(response => {
                commit('SET_QUESTION_DELETE', response.data)
            })
        }
    }
}
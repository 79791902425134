import * as Service from '@/services/company/company_service'


export default {
    namespace: true,
    state: {
        companies: [],
    },
    getters: {
        companies(state) {
            return state.companies
        }
    },
    mutations: {
        SET_COMPANIES(state, companies) {
            state.companies = companies
        },
        SET_COMPANY(state, company) {
            let result = state.companies.find(c => c.id === company.id)
            if(undefined !== result) {
                Object.assign(result, company)
            } else {
                state.companies.push(company)
            }
        },
        DELETE_COMPANY(state, id) {
            state.companies = state.companies.filter(company => company.id !== id)
        },
        SET_ADDRESS(state, data) {
            state.companies = state.companies.map(company => {
                if (parseInt(company.id) === parseInt(data.company_id)) {
                    let result = company.addresses.find(a => a.id === parseInt(data.id))
                    if (undefined === result) {
                        if (parseInt(data.is_main) === 1) {
                            company.addresses = company.addresses.map(address => {
                                address.is_main = 0
                                return address
                            })
                        }
                        company.addresses.push(data)
                    }
                    else {
                        company.addresses = company.addresses.map(address => {
                            if (parseInt(data.is_main) === 1) {
                                address.is_main = 0
                            }
                            if (address.id === data.id) {
                                return data
                            }

                            return address;
                        })
                    }
                }

                return company;
            })
        },
        DELETE_ADDRESS(state, data) {
            state.companies = state.companies.map(company => {
                if (parseInt(company.id) === parseInt(data.company_id)) {
                    if (company.addresses.length > 1) {
                        company.addresses = company.addresses.filter(a => a.id !== parseInt(data.id))
                        if(parseInt(data.is_main) === 1) {
                            company.addresses[0].is_main = 1
                        }
                    }
                }

                return company
            })
        },
        SET_PERSON(state, data) {
            state.companies = state.companies.map(company => {
                if (parseInt(company.id) === parseInt(data.company_id)) {
                    let result = company.persons.find(person => person.id === parseInt(data.id))
                    if (undefined === result) {
                        company.persons.push(data)
                    } else {
                        company.persons = company.persons.map(person => {
                            if (person.id === data.id) {
                                return data
                            }
                            return person
                        })
                    }
                }

                return company
            })
        },
        SET_PERSONS_CSV(state, data) {
            state.companies = state.companies.map(company => {
                if (parseInt(company.id) === parseInt(data[0]['company_id'])) {
                   company.persons = data
                }
                return company
            })
        },

        DELETE_PERSON(state, data) {
            state.companies = state.companies.map(company => {
                if (parseInt(company.id) === parseInt(data.company_id)) {
                    company.persons = company.persons.filter(person => person.id !== parseInt(data.id))
                }

                return company
            })
        },
        SET_CAD(state, data) {
            state.companies = state.companies.map(company => {
                if (parseInt(company.id) === parseInt(data.company_id)) {
                    let result = company.customer_access_data.find(cad => cad.id === parseInt(data.id))
                    if (undefined === result) {
                        company.customer_access_data.push(data)
                    } else {
                        company.customer_access_data = company.customer_access_data.map(cad => {
                            if (cad.id === data.id) {
                                return data
                            }
                            return cad
                        })
                    }
                }

                return company
            })
        },
        DELETE_CAD(state, data) {
            state.companies = state.companies.map(company => {
                if (parseInt(company.id) === parseInt(data.company_id)) {
                    company.customer_access_data = company.customer_access_data.filter(person => person.id !== parseInt(data.id))
                }

                return company
            })
        },
    },
    actions: {
        /* Companies */
        async companies({commit, state}) {
            if(state.companies.length > 0) {
                return;
            }

            let response = await Service.companies()
            await commit('SET_COMPANIES', response.data.data)
        },

        /* Company create */
        async companyCreate({commit}, data) {
            await Service.companyCreate(data).then(response => {
                commit('SET_COMPANY', response.data.data)
            })
        },

        /* Company edit */
        async companyEdit({commit}, data) {
            await Service.companyEdit(data, data.id).then(response => {
                commit('SET_COMPANY', response.data.data)
            })
        },

        /* Company delete */
        async companyDelete({commit}, id) {
            await Service.companyRemove(id).then(() => {
                commit('DELETE_COMPANY', id)
            }).catch(error => {
                console.log(error);
            })
        },

        /* Address create */
        async addressCreate({commit}, data) {
            await Service.addressCreate(data, data.id).then(response => {
                commit('SET_ADDRESS', response.data.data)
            })
        },

        /* Address edit */
        async addressEdit({commit}, data) {
            await Service.addressEdit(data, data.id).then(response => {
                commit('SET_ADDRESS', response.data.data)
            })
        },

        /* Address delete */
        async addressDelete({commit}, id) {
            await Service.addressDelete(id).then(response => {
                commit('DELETE_ADDRESS', response.data.data)
            }).catch(error => {
                console.log(error);
            })
        },

        /* Person create */
        async personCreate({commit}, data) {
            await Service.personCreate(data).then(response => {
                commit('SET_PERSON', response.data.data)
            })
        },

        /* Person edit */
        async personEdit({commit}, data) {
            await Service.personEdit(data, data.id).then(response => {
                commit('SET_PERSON', response.data.data)
            })
        },

        /* Person delete */
        async personDelete({commit}, id) {
            await Service.personDelete(id).then(response => {
                commit('DELETE_PERSON', response.data.data)
            })
        },

        /* CAD Customer access data create */
        async cadCreate({commit}, data) {
            await Service.cadCreate(data).then(response => {
                commit('SET_CAD', response.data.data)
            })
        },

        /* CAD Customer access data edit */
        async cadEdit({commit}, data) {
            await Service.cadEdit(data, data.id).then(response => {
                commit('SET_CAD', response.data.data)
            })
        },

        /* CAD Customer access data delete */
        async cadDelete({commit}, id) {
            await Service.cadDelete(id).then(response => {
                commit('DELETE_CAD', response.data.data)
            })
        },
    }
}
<template>
  <header class="mx-header">
<!--    <router-link :to="{name: 'dashboard'}" class="mx-header-logo"/>-->
    <div class="mx-cabinet-container mx-header-container">
      <nav class="mx-header-menu">
        <ul>
          <li>
              <img style="max-width: 86px; max-height: 30px; margin-right: 16px;margin-top: 7px;" src="/assets/images/logo.png" alt="Peepz GmbH">
          </li>
<!--          <li>-->
<!--            <router-link :to="{name: 'dashboard'}">Dashboard</router-link>-->
<!--          </li>-->
          <li>
            <router-link :to="{name: 'company'}">Firmen</router-link>
          </li>
          <li>
            <router-link :to="{name: 'documents'}">Dokumente</router-link>
          </li>
          <li>
            <router-link :to="{name: 'contact_person.list'}">Ansprechpartner</router-link>
          </li>
        </ul>
      </nav>
      <b-dropdown id="dropdown-right" right :text="getUserName" size="sm" variant="btn-peepz-default" class="mx-header-logout">
        <router-link :to="{name: 'setting'}" class="dropdown-item">
          <ph-faders :size="20" weight="light" />
          <span>{{ $t('setting.settings.title') }}</span>
        </router-link>
        <b-dropdown-item href="#" @click="logout" class="mx-logout">
          <ph-sign-out :size="20" weight="light" />
          <span>{{ $t('auth.logout') }}</span>
        </b-dropdown-item>
        <span class="dropdown-item" style="color: #a2a2a2; font-size: 12px; margin-top: 10px;">{{ options.version }}</span>
      </b-dropdown>
    </div>
  </header>
</template>

<script>

import { PhSignOut, PhFaders } from "phosphor-vue";

export default {
  components: {
    PhSignOut,
    PhFaders
  },
  data() {
    return {
      user: null
    }
  },
  created() {
    this.user = this.$store.getters.user
  },
  computed: {
    getUserName() {
      return  this.user.first_name[0] + '.' + this.user.last_name
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('signOut')
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="mx-wrapper mx-main">
<!--    <mx-preloader :loader-status="preloader"/>-->
    <Header/>
    <div class="mx-container">
      <router-view/>
    </div>
    <Footer/>
  </div>
</template>

<script>

import Header from "@/components/main/Header";
import Footer from "@/components/main/Footer";
import {activityWatcher} from "../scripts/watcher";

export default {
  components: {Header, Footer},
  data() {
    return {
      preloader: true,
      watcher: ''
    }
  },
  mounted() {
    this.watcher = activityWatcher(this.options.watcher_time);
    setTimeout(this.preload, this.options.preload_time);
  },
  destroyed() {
    clearTimeout(this.watcher)
  },
  methods: {
    preload() {
      this.preloader = false;
    }
  }
}
</script>

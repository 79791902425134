import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import Auth from './auth/_auth_store'
import Company from './company/_company_store'
import User from './user/_user_store'
import Faq from './faq/_faq_store'
import Document from './document/document_store'

export default new Vuex.Store({
    modules: {
        Auth,
        Company,
        User,
        Faq,
        Document
    },
    state: {},
    mutations: {},
    actions: {},

})

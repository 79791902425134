import store from '@/store'
import axios from 'axios'

import options from "@/options";
import language from "@/scripts/mx_language";

store.subscribe((mutation) => {
  switch (mutation.type) {
    case 'SET_TOKEN':
      if (mutation.payload) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${mutation.payload}`;
        sessionStorage.setItem('token', mutation.payload) // localStorage OR sessionStorage.
      } else {
        axios.defaults.headers.common['Authorization'] = null;
        sessionStorage.removeItem('token')
      }
      break;

    case 'SET_LANGUAGE':
      if (mutation.payload) {
        language.set(mutation.payload);
        localStorage.setItem('user_lang', mutation.payload)
      } else {
        language.set(options.language);
      }
      break;
  }
});


/* Middleware */
import auth from '@/middleware/auth'

export default [
    {
        path: '/cabinet/company/:company_id/address/create',
        name: 'address.create',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/address/AddressCreateView'),
    },
    {
        path: '/cabinet/company/:company_id/address/edit',
        name: 'address.edit',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/address/AddressEditView'),
    }
];
import * as Service from '@/services/settings/settings_service'
import * as ContactPersons from '@/services/contact_persons/contact_persons'
import {contactPersons} from "@/services/contact_persons/contact_persons";

export default {
    namespace: true,
    state: {
        user: {},
        contact_persons: []
    },
    getters: {
        user(state) {
            return state.user
        },
        contact_persons(state) {
            return state.contact_persons
        }
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
        },
        SET_CONTACT_PERSONS(state, contact_persons) {
            state.contact_persons = contact_persons
        }
    },
    actions: {
        /* User edit */
        async userEdit({commit}, data) {
            await Service.userUpdate(data, data.user_id).then(response => {
                commit('SET_USER', response.data.data)
                commit('SET_LANGUAGE', response.data.data.language)
            })
        },

        /* ContactPersons */
        async getContactPersons({commit}) {
            await ContactPersons.contactPersons().then(response => {
                commit('SET_CONTACT_PERSONS', response.data.data)
            })
        },
    }
}

import { http } from "../http_service";


/** COMPANY */
export function companies() {
    return http().get(`/companies`);
}

export function companyCreate(data) {
    return http().post(`/companies`, data);
}

export function companyEdit(data, id) {
    return http().put(`/companies/${id}`, data);
}

export function companyDelete(id) {
    return http().delete(`/companies/${id}`);
}

export function companyRemove(id) {
    return http().delete(`/companies/remove/${id}`);
}


/** ADDRESS  */
export function addresses() {
    return http().get(`/addresses`);
}

export function addressCreate(data) {
    return http().post(`/addresses`, data);
}

export function addressEdit(data, id) {
    return http().put(`/addresses/${id}`, data);
}

export function addressDelete(id) {
    return http().delete(`/addresses/${id}`);
}

/** PERSON */
export function personCreate(data) {
    return http().post(`/contact-persons`, data);
}
export function personEdit(data, id) {
    return http().put(`/contact-persons/${id}`, data);
}
export function personDelete(id) {
    return http().delete(`/contact-persons/${id}`);
}

/** Cad Customer access data */
export function cadCreate(data) {
    return http().post(`/customer-access-data`, data);
}
export function cadEdit(data, id) {
    return http().put(`/customer-access-data/${id}`, data);
}
export function cadDelete(id) {
    return http().delete(`/customer-access-data/${id}`);
}

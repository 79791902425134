export default {

    // Company
    "company": 'Firma',
    "companies": 'Firmen',
    "new_company": "Neue Firma",
    "companies_not_found": "Sie haben noch keine registrierte Firma",
    "edit_company": "Firma ändern",
    "edit_company_title": "Firma und die Hauptadresse ändern",
    "company_name": "Name der Firma",
    "company_name_short": "Abgekürzter Firmenname",
    "customer_number": "Kundennummer",
    "email": "Email",
    "company_phone": "Telefonnummer",
    "yes": "Ja",
    "no": "Nein",

    // GEO
    "country": 'Land',
    "city": 'Stadt',
    "postcode": 'Postleitzahl',
    "street": 'Straße und Hausnummer',
    "main_address": "Hauptadresse",

    // Address
    "location": "Standort",
    "locations": "Standorte",
    "new_location": "Standort",
    "edit_location": "Standort ändern",

    // Buttons
    "abort": "Abbrechen",
    "save": "Speichern",
    "delete": "Löschen",

    // Errors
    "errors": {
        "required": "Pflichtfeld.",
        "number": "Nur Zahlen erlaubt.",
        "valid": "Feld ist ungültig",
    },

    // Prompt
    "prompt": {
        "company": {
            "title": "Möchten Sie die Firma löschen?",
            "subtitle": "Alle Daten über das Unternehmen und die damit verbundenen Daten werden ohne Rückgabe gelöscht."
        },
        "address": {
            "title": "Möchten Sie diese Adresse löschen?",
            "subtitle": "Alle Daten über diese adresse und die damit verbundenen Daten werden ohne Rückgabe gelöscht."
        },
        "person": {
            "title": "Ansprechpartner löschen?",
            "subtitle": "Alle Daten über dieser Ansprechpartner und die damit verbundenen Daten werden ohne Rückgabe gelöscht."
        },
        "cad": {
            "title": "Zugangsdaten löschen?",
            "subtitle": "Alle Daten über dieser Zugangsdaten und die damit verbundenen Daten werden ohne Rückgabe gelöscht."
        }
    },

    // Time
    "opening_hours": "Öffnungszeiten",
    "closed": 'Geschlossen',
    "day_of_week": {
        'mo': 'Montag',
        'di': 'Dinstag',
        'mi': 'Mittwoch',
        'do': 'Donerstag',
        'fr': 'Freitag',
        'sa': 'Samstag',
        'so': 'Sonntag',
    },
    "day_of_week_short": {
        'mo': 'Mo',
        'di': 'Di',
        'mi': 'Mi',
        'do': 'Do',
        'fr': 'Fr',
        'sa': 'Sa',
        'so': 'So',
    },

    // Person
    "person": {
        "title": "Ansprechpartner",
        "new_person": "Ansprechpartner",
        "dropdown": {
            "title": "Ansprechpartner",
            "new_person": "Neue Ansprechpartner",
        },
        "csv": {
            "load": "Hochladen",
            "button_export": "CSV File Exportieren",
            "button_import": "CSV File Importieren",
            "errors": {
                "500": "Die Datei ist beschädigt oder stimmt nicht mit den Parametern überein. Bitte wählen Sie eine andere Datei."
            },
            "prompt": {
                "title": "Möchten Sie neue Ansprechpartner hochladen?",
                "subtitle": "Beim Hochladen neuer Kontakte werden alle Kontakte in der Datenbank gelöscht! ",
            },
        },
        "table": {
            "not_found": "Sie haben noch keinen registrierten Ansprechpartner",
            "position": "Position",
            "first_name": "Vorname",
            "last_name": "Nachname",
            "email": "Email",
            "phone": "Telefonnummer",
            "identification": "Personalnummer"
        },
        "form": {
            "position": "Position",
            "first_name": "Vorname",
            "last_name": "Nachname",
            "phone": "Telefonnummer",
            "birthday": "Geburtstag",
            "email": "Email",
            "gender": "Geschlecht",
            "put_through": "Durchstellen (Telefongespräch weiterleiten)",
            "gender_select": "Bitte wählen Sie ein Geschlecht"
        }
    },

    // Customer access Data
    "cad": {
        "subtitle": "Zugangsdaten",
        "title": "Title",
        "description": "Beschreibung",
        "url": "URL",
        "login": "Login",
        "password": "Passwort",
        "is_active": "Ist aktiv",
        "btn": {
            "new": "Neu zugangsdaten",
            "save": "Speichern",
            "delete": "Löschen",
            "edit": "Ändern",
        },
        "table": {
            "not_found": "",
        }
    }
}

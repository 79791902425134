import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import auth from "@/middleware/auth";

import options from "../options";

/* Routes */
import Auth from './_auth_router'
import Cabinet from './_cabinet_router'
import Company from './_company_router'
import Address from './_address_router'
import Person from './_person_router'
import MyContactPerson from './_my_contact_person_router'
import faq from "./_faq_router.js"
import cad from "./_cad_router.js"
import documents from "./_documents_router"

const routes = [
    ...Auth,
    ...Cabinet,
    ...Company,
    ...Address,
    ...Person,
    ...MyContactPerson,
    ...faq,
    ...cad,
    ...documents,
    {
        path: '/setting',
        name: 'setting',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/SettingView'),
    },
    {
        path: '*',
        meta: {
            layout: 'empty'
        },
        component: () => import('@/views/NotFoundView')
    }
]

const router = new VueRouter({
    mode: options.route_mode,
    base: process.env.BASE_URL,
    routes
})

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) {
        return context.next;
    }

    return (...parameter) => {
        context.next(...parameter);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware})
    }
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const context = {from, next, router, to};
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware})
    }
    return next();
});

export default router

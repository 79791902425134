export default {
  "title": "FAQ",
  "search": "Suchen",
  "welcome_text":"Begrüßungstext",
  "save": "Speichern",
  "edit": "FAQ ändern",
  "question": {
    "title": "Fragen",
    "button": "Neue Frage",
    "button_csv_export": "Fragen CSV Exportieren",
    "create": "Neue Frage",
    "update": "Frage ändern",
    "csv": {
      "load": "Fragen hochladen",
      "button_export": "Fragen CSV Exportieren",
      "button_import": "Fragen CSV hochladen ",
      "prompt": {
        "title": "Möchten Sie neue Fragen hochladen? ",
        // "subtitle": "Beim Hochladen neuer Fragen werden alle Fragen gelöscht, die sich derzeit in der Datenbank befinden!",
        "subtitle": "Beim Hochladen neuer Fragen werden alle alten Fragen in der Datenbank gelöscht!",
      },
    },
    "prompt": {
      "title": "Möchten Sie diese frage löschen?",
      "subtitle": "Diese frage werden ohne Rückgabe gelöscht.",
    },
    "field": {
      "title": "Frage",
      "text": "Antwort",
      "notice": "Notiz",
    }
  }
}

import * as Service from '@/services/document/document_service'

export default {
    namespace: true,
    state: {
        document_categories: [],
        document_category: {},
        documents: [],
        document: {},
    },
    getters: {
        document_categories(state) {
            return state.document_categories
        },
        document_category(state) {
            return state.document_category
        },
        documents(state) {
            return state.documents
        },
        document(state) {
            return state.document
        }
    },
    mutations: {
        SET_DOCUMENT_CATEGORIES(state, document_categories) {
            state.document_categories = document_categories
        },
        SET_DOCUMENT_CATEGORY(state, document_category) {
            state.document_category = document_category
        },
        SET_DOCUMENTS(state, documents) {
            state.documents = documents
        },
        SET_DOCUMENTS_AFTER_DELETE(state, document_id) {
            let index = state.documents.findIndex(document => document.id === document_id)
            state.documents.splice(index, 1)
        },
        SET_DOCUMENT(state, document) {
            state.document = document
        },
    },
    actions: {
        /** Categories **/
        async documentCategories({commit}) {
            await Service.documentCategories().then(response => {
                commit('SET_DOCUMENT_CATEGORIES', response.data.data)
            })
        },

        /** Documents **/
        async allDocuments({commit}) {
            await Service.allDocuments().then(response => {
                commit('SET_DOCUMENTS', response.data)
            })
        },
        async documentCreate({commit}, id) {
            await Service.documentCreate(id)
        },
        async documentEdit({commit}, id) {
            await Service.documentEdit(id).then(response => {
                commit('SET_DOCUMENT', response.data)
            })
        },
        async documentUpdate({commit}, data) {
            await Service.documentUpdate(data)
        },
        async documentDelete({commit}, id) {
            await Service.documentDelete(id).then(() => {
                commit('SET_DOCUMENTS_AFTER_DELETE', id)
            })
        },
    }
}

import options from "@/options";

export function activityWatcher(minutes = 15) {

    const maxInactivity = (60 * minutes); //Five minutes. 60 x 5 = 300 seconds.
    let secondsSinceLastActivity = 0;

    let s = setInterval(function() {
        secondsSinceLastActivity++;

        if (options.watcher_debug) {
            //console.log(secondsSinceLastActivity + ' seconds since the user was last active');
        }

        if(secondsSinceLastActivity > maxInactivity){
            sessionStorage.removeItem('token');
            location.replace('/unlock');
        }
    }, 1000);

    function activity() {
        secondsSinceLastActivity = 0;
    }

    let activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

    activityEvents.forEach((eventName) => {
        document.addEventListener(eventName, activity, true);
    });

    return s;
}

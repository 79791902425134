/* Middleware */
import auth from '@/middleware/auth'

export default [
    {
        path: '/cabinet/company',
        name: 'company',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        redirect: "/cabinet/company/all",
        component: () => import('@/views/cabinet/CompanyView'),
        children: [
            {
                path: 'all',
                name: 'company.all',
                meta: {
                    layout: 'main',
                    middleware: [auth]
                },
                component: () => import('@/views/cabinet/company/CompanyListView'),
            },
            {
                path: ':id',
                name: 'company.show',
                meta: {
                    layout: 'main',
                    middleware: [auth]
                },
                component: () => import('@/views/cabinet/company/CompanyShowView'),
            },
            {
                path: ':id/edit',
                name: 'company.edit',
                meta: {
                    layout: 'main',
                    middleware: [auth]
                },
                component: () => import('@/views/cabinet/company/CompanyEditView'),
            }
        ]
    }
];
/* Middleware */
import auth from '@/middleware/auth'

export default [
    {
        path: '/cabinet/dashboard',
        name: 'dashboard',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        redirect: "/cabinet/company/all",
        component: () => import('@/views/cabinet/DashboardView')
    },
    {
        path: '/cabinet/contact-person',
        name: 'contact-person',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/ContactPersonView')
    }
];
<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>

import AuthLayout from "@/layouts/AuthLayout";
import MainLayout from "@/layouts/MainLayout";
import EmptyLayout from "@/layouts/EmptyLayout";
import language from "@/scripts/mx_language";

export default {
  components: {
    AuthLayout, MainLayout, EmptyLayout
  },
  mounted() {
    if (localStorage.user_lang) {
      language.set(localStorage.user_lang)
    }
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout';
    }
  }
}
</script>
/* Middleware */
import auth from '@/middleware/auth'

export default [
    {
        path: '/cabinet/company/:company_id/person/create',
        name: 'person.create',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/person/PersonCreateView'),
    },
    {
        path: '/cabinet/company/:company_id/person/edit',
        name: 'person.edit',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/person/PersonEditView'),
    },
    {
        path: '/cabinet/company/:company_id/person/csv/import',
        name: 'person.csv.import',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/person/PersonCSVImportView'),
    }
];
/* Middleware */
import auth from '@/middleware/auth'

export default [
    {
        path: '/cabinet/contact-persons',
        name: 'contact_person.list',
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('@/views/cabinet/my_contact_person/MyContactPersonView'),
    },
];
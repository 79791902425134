<template>
  <div class="mx-footer">
    <div class="mx-footer-container">
      <span class="mx-footer-company">{{ $t('copyright', {'year': new Date().getFullYear()}) }}</span>
      <span class="mx-footer-version">{{ options.version }}</span>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>